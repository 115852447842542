import React, { useEffect, useState } from 'react';
import loadable from '@loadable/component';
import { useStatsigClient } from '@statsig/react-bindings';
import bemCn from 'bem-cn';
import { omit } from '../../utils';
import { states } from '../../consts/states';
import CookieService from '../../services/cookie.service';
import { TOP_CITIES } from '../../consts/top-cities';
import { EVENTS_NAME } from '../../consts/event.consts';
import useMobileScreen from '../../services/hooks/useMobileScreen';
import { RouteFormatter } from '../../routes';
import { USER_TEST_GROUP } from '../../consts/profile.const';
import { getSearchFiltersForServer } from '../listings/utils';
import { COOKIE_PARAM_NAME } from '../../consts/cookies.consts';
import { SF_SIZE_OPTIONS } from '../../consts/search-filter.const';
import backgroundMobile from '../../assets/img/sniff-seo-bg-header-m.jpg?sizes[]=500';
import background from '../../assets/img/sniff-seo-bg-header-d.jpg?sizes[]=1200,sizes[]=1920';
import { getCountSearchFilters } from '../../components/search/utils';
import DesignedForPrivate from '../../components/designed-for-private';
import RenderDetailedHome from './render-detailed-home';
import SniffButton from '../../components/sniff-button';
import BigPicture from '../../components/big-picture';
import './style.scss';

import { SIGN_UP_SRC } from '../../consts/profile.const';
import { STATSIG_EVENTS } from '../../consts/statsig.consts';

const useMutateViewHeroSignup = require('../../services/hooks/useMutateViewHeroSignup');

const b = bemCn('home');
const bSignUp = bemCn('host-landing');

const RenderSignUpEmail = loadable(() => import('../host-landing/render-sign-up-email'));

const RenderBannerContent = ({ isSignUpTest, isFocused, setIsFocused, toasterDanger }) => {
    const { logEvent } = useStatsigClient();
    const onSuccess = () => logEvent(STATSIG_EVENTS.GUEST_SIGNUP);

    if (isSignUpTest) {
        return (
            <>
                <div className="d-md-none">
                    <div className="circle-overlay" />
                </div>
                <div className={b('container')}>
                    <div className={bSignUp('header sign-up-test')}>
                        {isFocused && <h1 className="snif-m2 snif-semibold text-dark mb-3">Sign up</h1>}
                        {!isFocused && <h1 className="snif-m1 snif-l1-pc mb-2 mb-md-4">Rent safe and private dog parks hosted by locals</h1>}
                        <div className={bSignUp('signup-form')}>
                            <RenderSignUpEmail
                                setIsFocused={setIsFocused}
                                isFocused={isFocused}
                                showSubText={isFocused}
                                signUp
                                b={bSignUp}
                                src={SIGN_UP_SRC.HOME_FORM_WEB}
                                platform="web"
                                onSuccess={onSuccess}
                                toasterDanger={toasterDanger}
                                useStateSSR
                            />
                        </div>
                        <div className="mt-4 text-center">
                            {isFocused ? (
                                <p className="snif-p snif-semibold text-medium text-center mt-2">
                                    I already have an account, <a href={RouteFormatter.signIn()}>Sign in</a>
                                </p>
                            ) : (
                                <a href={RouteFormatter.listings({})} className="snif-p snif-medium browse-spots-link">
                                    Browse spots for now
                                </a>
                            )}
                        </div>
                    </div>
                </div>
            </>
        );
    }

    return (
        <>
            <h1 className="snif-l2 snif-xl-pc snif-semibold text-white text-center">Rent safe and private dog parks hosted by locals</h1>
            <a href={RouteFormatter.listings({})}>
                <SniffButton className="main-action-button" size="lg">
                    Explore spots near me
                </SniffButton>
            </a>
        </>
    );
};

const HomePage = ({ useStateSSR, useEffectSSR, useRefSSR, homePageSSR, statsigLoading, useMutateTrackEvent, serverSSR, toasterDanger }) => {
    const isMobile = useMobileScreen();
    const { trackEvent } = useMutateTrackEvent ? useMutateTrackEvent() : {};
    const [isFocused, setIsFocused] = useState(false);
    const [isSignUpTest, setIsSignUpTest] = useStateSSR(null);
    const { mutateViewGuestHeroSignup } = serverSSR ? {} : useMutateViewHeroSignup.default();
    const { checkGate, getFeatureGate } = useStatsigClient();
    const isGate = statsigLoading ? null : checkGate(USER_TEST_GROUP.GUEST_HERO_SIGNUP.toLowerCase());
    const isGateDisabled = statsigLoading ? null : getFeatureGate(USER_TEST_GROUP.GUEST_HERO_SIGNUP.toLowerCase()).ruleID === 'disabled';

    useEffect(() => {
        if (useMutateTrackEvent) {
            trackEvent({ name: EVENTS_NAME.home_view });
        }
    }, [useMutateTrackEvent]);

    useEffect(() => {
        if (isGate !== null) {
            if (!isGateDisabled) {
                CookieService.set(COOKIE_PARAM_NAME.GUEST_HERO_SIGNUP, isGate ? 'NEW' : 'OLD', { expires: 90 });
                mutateViewGuestHeroSignup({
                    variables: {
                        testGroup: isGate,
                        sessionUuid: CookieService.get(COOKIE_PARAM_NAME.SESSION_UUID),
                    },
                });
            }
            setIsSignUpTest(isGate);
        }
    }, [isGate]);

    const handleSubClick = (e, opt) => {
        e?.preventDefault();
        const filtersNew = getSearchFiltersForServer(opt);
        const count = 1;
        const filters = omit(filtersNew, ['availability', 'openNow']);
        CookieService.set(COOKIE_PARAM_NAME.SEARCH_FILTERS, JSON.stringify({ filters, count }), { expires: 365 });
        if (isMobile) {
            window.open(RouteFormatter.listings({}));
        } else {
            window.open(RouteFormatter.listings({}), '_blank');
        }
    };

    const handleExplore = (type) => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        if (type === 'Size') {
            const filtersNew = getSearchFiltersForServer({ showSize: true, minimumSize: SF_SIZE_OPTIONS[1] });
            const count = getCountSearchFilters(filtersNew);
            const filters = omit(filtersNew, ['availability', 'openNow']);
            CookieService.set(COOKIE_PARAM_NAME.SEARCH_FILTERS, JSON.stringify({ filters, count }), { expires: 365 });
        }
        window.open(RouteFormatter.listings({}));
    };

    const handleSubStatsClick = (type) => {
        const filtersNew = getSearchFiltersForServer({});
        filtersNew.amenities = [type];
        const count = getCountSearchFilters(filtersNew);
        const filters = omit(filtersNew, ['availability', 'openNow']);
        CookieService.set(COOKIE_PARAM_NAME.SEARCH_FILTERS, JSON.stringify({ filters, count }), { expires: 365 });
        window.open(RouteFormatter.listings({}));
    };

    return (
        <>
            <div className={b()}>
                <div className="main-action-title">
                    <BigPicture
                        positionRelative={isSignUpTest && isMobile}
                        background={background}
                        backgroundSm={backgroundMobile}
                        noBackground={isSignUpTest ? !isMobile : true}
                        right={true}
                        height={700}
                        heightSm={isSignUpTest ? 200 : 360}
                        alt="Sniffspot main banner"
                    >
                        <RenderBannerContent {...{ isMobile, isSignUpTest, isFocused, setIsFocused, toasterDanger }} />
                    </BigPicture>
                    <DesignedForPrivate banner />
                </div>
                <RenderDetailedHome
                    SSR
                    {...{
                        isMobile,
                        cities: TOP_CITIES,
                        states,
                        handleSubClick,
                        handleExplore,
                        handleSubStatsClick,
                        useStateSSR,
                        useEffectSSR,
                        useRefSSR,
                        homePageSSR,
                        serverSSR,
                    }}
                />
            </div>
        </>
    );
};

export default HomePage;
